.main-container {
  max-width: 950px;
  margin-bottom: 20px;
}

.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgba(255, 255, 255, 0.6);
    width: 100%;
    margin-top: 200px;
  }

  .about-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
  }

  .about-header-line {
    height: 2px;
    flex-grow: 1;
    background-color: rgba(255, 255, 255, 0.10);
    margin-left: 24px;
  }

  .about-description-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }

  .about-img {
    height: 400px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    margin-right: 20px;
  }

  .about-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 600px;
  }

  .about-title {
    font-size: 30px;
    max-height: none;
  }

  .about-bio {
    display: flex;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 0px;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    width: 100%;
  }

  .about-stats {
    padding: 20px 60px;
    width: 100%;
    border: 1px solid rgba(21, 106, 115, 1);
    border-radius: 10px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 12px;
    box-sizing: border-box;
  }

  .about-stats-text-content {
    display: flex;
    flex-direction: row;
    gap: 5px; 
  }

  .about-stats-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    width: 100%;
  }

  .about-stats-text {
    margin: 0px;
    font-size: 14px;
  }

  .about-container {
    display: flex;
    /* width: 500px; */
    height: 300px;
    margin: 240px auto 24px auto;
  }

  .division {
    display: flex;
    position: absolute;
    right: 1px;
    margin: 500px 0px 0px 0px;
    width: 50px;
    height: 0px;
    border: 2px solid rgba(21, 106, 115, 1);
  }

  .divison-number {
    display: flex;
    margin-top: 480px;
    position: absolute;
    right: 60px;
    font-size: 40px;
    color: #918e9b33;
  }

  .division-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
  }

  
@media screen and (max-width: 1010px) {
  .about-stats-text {
    font-size: 12px;
  }
  .about-stats {
    padding: 8px 12px;
  }
}

@media screen and (max-width: 1000px) {
  .main-container {
    padding: 20px;
  }
}

  @media screen and (max-width: 940px) {
    .about-stats-text {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 900px) {
    .about-stats {
      padding: 8px 12px;
    }
  }

  @media screen and (max-width: 800px) {
    .main-container {
      width: 100%;
      padding: 20px;
    }

    .about {
      width: 100%;
    }

    .about-header {
      width: 100%;
      flex-direction: column;
    }

    .about-header-line {
      display: none;
    }

    .about-description-row {
      flex-direction: column;
      align-items: center;
    }

    .about-info-container {
      width: 100%;
    }

    .about-bio {
      width: 100%;
      margin-top: 25px;
      text-align: center;
    }

    .about-stats {
      width: 100%;
      margin-top: 25px;
      margin-bottom: 25px;
      padding: 8px 12px;
    }

    .about-stats-text {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 420px) {
    .about-stats-row {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-left: 8px;
      gap: 5px;
    }

    .about-stats {
      align-items: center;
    }

    .about-stats-text {
      font-weight: 700;
    }

    .about-stats-text-content {
      justify-content: space-between;
      width: calc(100% - 8px);
    }
  }