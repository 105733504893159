
.box {
    position: relative;
    width: 370px;
    height: 270px;
    background-size: cover;
    float: left;
    margin: 15px;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 10px;
  }

  .icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #262e30;
    transition: width 500ms ease, height 500ms ease, left 500ms ease, top 500ms ease, border-radius 500ms ease;
    z-index: 2;
  }

  .overlay {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(34, 27, 27, 0.692);
  }

  .box:hover .icon {
    top: 20px;
    left: calc(50% - 40px);
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .box .icon .img {
    width: 52px;
    height: 52px;
    transition: width 500ms ease, height 500ms ease;
  }

  .box:hover .img {
    width: 28px;
    height: 28px;
  }

  .img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 80px;
    transition: 0.5s;
    color: #fff;
  }

  .media-container-text-container {
    transition: bottom 500ms ease;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
  }

  .box .media-container-text {
    font-size: 12px;
    color: white;
    margin: 0;
  }

  .media-container-title-container {
    position: absolute;
    bottom: -64px;
    transition: bottom 500ms ease;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
  }

  .box .media-container-title {
    font-size: 18px;
    color: white;
    margin: 0;
  }

  .box:hover .media-container-title-container {
    bottom: 64px;
   }


  .box:hover .media-container-text-container {
   bottom: 115px;
  }

  .media-container-icon {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    gap: 12px;
  }

  .icon-container {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

    .icon-iamge {
        width: 40px;
        height: 40px;
    }

    .icon-iamge-fa {
      width: 40px;
      height: 40px;
      color: rgb(255, 255, 255);
    }

  .icon-folder-iamge-fa {
    width: 40px;
    height: 40px;
    color: rgb(212, 212, 126);
    }

  .instagram {
    width: 40px;
    height: 40px;
    bottom: -45px;
    transition: bottom 500ms ease;
    border-radius: 8px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  }

  .discord {
    width: 40px;
    height: 40px;
    bottom: -45px;
    transition: bottom 500ms ease;
    border-radius: 8px;
    background: #738ADB;
    color: white;
    display: flex;
    flex-direction: row;
  }

  .linkedIn {
    width: 40px;
    width: 40px;
    height: 40px;
    bottom: -45px;
    transition: bottom 500ms ease;
    border-radius: 8px;
    background:white;
    color: #007bb6;
    display: flex;
    flex-direction: row;
  }

  .github {
    width: 40px;
    width: 40px;
    height: 40px;
    bottom: -45px;
    transition: bottom 500ms ease;
    border-radius: 8px;
    background: white;
    color: black;
    display: flex;
    flex-direction: row;
  }

  

  .main {
    background: linear-gradient(90deg, #13191a 1.18%, #000000 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .media-list {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 9px;
    overflow-x: auto;
    margin-top: 40px;
  }


  @media screen and (max-width: 1260px) {
    .box {
      height: 203px;
      width: 278px;
    }
    .box:hover .media-container-title-container {
      bottom: 16px;
    }
    .box:hover .icon {
      width: 60px;
      height: 60px;
      left: calc(50% - 30px);
    }
    .img {
      width: 50%;
      height: 50%;
    }
  }



  @media screen and (max-width: 989px) {
    .media {
      display: none;
    }
  }

  @media screen and (max-width: 700px) {
    .media {
      display: none;
    }
  }

