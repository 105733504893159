.contact-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .contact-title {
    color: white;
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .email-contact {
    font-size: 14px;
    margin: 0;
  }
  
  .linkedin-contact {
    text-decoration: none;
    color: inherit;
    background: none;
    padding: 0;
    border: none;
    font-size: 14px;
    cursor: pointer;
    font-family: "Work Sans", sans-serif;
    font-weight: 600;
  }
  
  .github-contact {
      text-decoration: none;
      color: inherit;
      background: none;
      padding: 0;
      border: none;
      font-size: 14px;
      cursor: pointer;
      font-family: "Work Sans", sans-serif;
      font-weight: 600;
  }
  
  .close-icon {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: rgb(63, 63, 63);
  }

  .contact-menu-container {
    position: fixed;    
    justify-content: center;
    align-items: center;
    background:rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    transition: opacity 0.25s ease;
    opacity: 0;
    display: none;
    z-index: 3;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  
  /* When you want to show the menu, you can add this class to .contactMenuContainer */
  .show {
    opacity: 1;
    display: flex;
    pointer-events: all;
  }
  
  .contact-menu {
    position: relative;
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    color: rgb(196, 196, 196);
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 300px;
    border-radius: 10px;
    background: linear-gradient(90deg, #273032 1.18%, #201f1f 100%);
  
    animation: pulse-ring 250ms ease;
  
    @keyframes pulse-ring {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 0.25;
      }
      50% {
        opacity: 0.5;
      }
      75% {
        opacity: 0.75;
      }
      100% {
        opacity: 1;
      }
    }
  }

  .contactMenuContainer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background:rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s ease, visibility 0.25s linear;
  }
  
  /* When you want to show the menu, you can add this class to .contactMenuContainer */
  .show {
    opacity: 1;
    visibility: visible;
  }
  
  .copy-icon {
    cursor: pointer;
  }
  
  .email-contact-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }
  
  .email-contact-icon {
    color: white;
  }
  
  .link-active {
    color: rgba(21, 106, 115, 1);
    transition: color 250ms eas-in-out;
  }
  
  .description-contact {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    margin-bottom: 24px;
    margin-top: 0;
  }
  
  .contact-links-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .link1-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
  
  .link1-icon {
    width: 12px;
    height: 12px;
  }
  
  .link2-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
  
  .link2-icon {
    width: 12px;
    height: 12px;
  }
  
  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .contact-title {
    color: white;
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .email-contact {
    font-size: 14px;
    margin: 0;
  }
  
  .linkedin-contact {
    text-decoration: none;
    color: inherit;
    background: none;
    padding: 0;
    border: none;
    font-size: 14px;
    cursor: pointer;
    font-family: "Work Sans", sans-serif;
    font-weight: 600;
  }
  
  .github-contact {
      text-decoration: none;
      color: inherit;
      background: none;
      padding: 0;
      border: none;
      font-size: 14px;
      cursor: pointer;
      font-family: "Work Sans", sans-serif;
      font-weight: 600;
  }
  
  .close-icon {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: rgb(63, 63, 63);
  }

