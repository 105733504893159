@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.pokemon-class-container {
    width: 100%;
}

/* changed width from 100% to 90% */

.container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.08);
    padding: 0 24px;
}

.pokemon--name {
    display: flex;
    justify-content: center;
    left: 50px;
    color: white;
    font-size: 20px;
    margin-bottom: 12px;
}

.pokemon--image {
    display: flex;
    justify-content: center;
    width: 200px;
}

.button--stats {
    font-family: 'Inter', sans-serif;
    background: transparent;
    border: none;
    display: block;
    margin-top: 18px;
    font-size: 14px;
    outline: none;
    position: relative;
    transition: 0.3s;
    color: white;
    white-space: normal;
    cursor: pointer;
}


.button--stats:hover {
    color: #57bd84; /* change the color when the cursor hovers over the button */
}

.button--stats:before {
    content: '';
    border-bottom: 1px solid white;
    padding: 0 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0px;
    margin: 0 auto;
    width: 0;
    transition: 0.3s;
}

.button--stats:hover:before {

    width: 100%;
    padding: 0; /* the other part of the trick */
    border-bottom: 1px solid #57bd84; /* change the color of the line under the button when the cursor hovers over it */
}

.button--stats:active {
    background: white;
    color: white;
    transition: none;
}

.image--container {
    display: flex;
    flex-direction: column;
    height: 280px;
    width: 230px;
    align-items: center;
    justify-content: center;
}

.button--container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
}

.about--container--conditional {
    color: white;
    font-size: 16px;
    font-weight: 500;
}

.button--stats--container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 0 48px;
}

.image--unknown {
    color: white;
    display: flex;
    justify-content: center;
    width: 200px;
    height: 200px;
}

.stats-info-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
