* {
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
  width: 950px;
}

@media screen and (max-width: 1010px) {
  .content {
    width: 800px;
  }
}

@media screen and (max-width: 640px) {
  .content {
    width: 100%;
  }
}


/* primary gray #162325, brand color #0D4248, link for colors https://www.color-hex.com/color/0d4248 */
