.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgba(255, 255, 255, 0.6);
    width: 100%;
    margin-top: 200px;
}

.projects-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
}

.title-text {
    font-size: 30px;
    max-height: none;
}

.projects-header-line {
    height: 2px;
    flex-grow: 1;
    background-color: rgba(255, 255, 255, 0.10);
    margin-left: 24px;
}

.header-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
}

.projects-description {
    margin-top: 0;
    font-size: 14px;
    max-width: 1100px;
}

.carousel {
    display: flex;
    justify-content: center;
    border-radius: 10px;
  }

  .carousel .slide img {
    border-radius: 10px;
    height: 450px;
  }

  /* PROJECTS-CONTENTS CHANGES */
  /* removed padding: 20px 60px; */
  /* margin top changed from 17px to 50px */
  /* changed aling-items from "start" to "center" */
  /* changed hard coded width from 800px to 850px */

  .projects-contents {
    width: 100%;
    border-radius: 10px;
    margin-top: 50px;
    font-size: 8px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    padding: 10px 24px 10px 24px;
    position: relative;
  }

  .content-box {
    display: flex;
    width: 260px;
    height: 260px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    border-radius: 3px;
    text-align: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px 12px;
  }

  .content-icon {
    width: 52px;
    height: 52px;
    color: white;
  }

  .content-title {
    font-size: 24px;
    color: white;
    margin-top: 0;
  }

  .content-description {
    text-align: center;
    font-size: 12px;
  }

  .control-prev, .control-next {
    display: block;
    height: 100%;
}

  .control-prev {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .control-next {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  p.carousel-status {
    display: none;
}

  .projects-title-text {
    font-size: 20px;
  }

  .projects-description-text {
      font-size: 14px;
      margin-top: 4px;
  }

  

  @media screen and (max-width: 800px) {
    .projects-header-line {
      display: none;
    }
    .projects-description {
      width: 100%;
      margin-top: 25px;
      text-align: center;
    }

    .projects-description-container {
      width: 75%;
    }
  }

  @media screen and (max-width: 640px) {
    .projects-description {
      width: 100%;
      margin-top: 25px;
      text-align: center;
    }

    .header-container {
      margin: 0;
    }
  }

  @media screen and (max-width: 510px) {
    .title-text {
      margin: 0;
    }
  }