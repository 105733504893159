.icons-container {
    width: 100%;
    height: 40px;
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
    background-color: rgba(255, 255, 255, 0.04);
    padding: 64px 0;
  }

.icon-image-mobile {
        width: 24px;
        height: 24px;
        filter: grayscale(100%);
    }

.media-mobile-icon-buttons {
    width: 40px;
    height: 40px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.12);
    }

@media screen and (max-width: 989px) {
    .icons-container {
        display: flex;
        gap: 48px;
    }
}    

@media screen and (max-width: 700px) {
    .icons-container {
          display: flex;
        }
    }



