.header-description {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 800px;
    font-size: 8vw;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 900;
    color: #fff;
    min-height: 100vh;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 24px;
    gap: 20px;
    margin-right: 8px;
    max-width: 100vw;
  }

  .header, h1 {
    max-height: 20px;
    transition: all .2s ease-in-out;
  }

  .header-element {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    color: rgba(255, 255, 255, 0.6);
  }

  .header-element:hover {
    transform: scale(1.1);
  }

  .personal-image {
    border-radius: 50%;
    margin: 0;
    width: 300px;
    height: 300px;
    object-fit: contain;
    background-color: black;
    box-shadow: 0 6px 12px black;
  }

  .header-title1 {
    margin-top: 16px;
    font-size: 100px;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 20px;
  }

  .header-subtitle {
    margin-top: 8px;
    font-size: 24px;
    color: rgba(255, 255, 255, 0.6);
  }

.navbar-container {
  width: 100%;
  position: relative;
  height: 800px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(https://res.cloudinary.com/df0ll615k/image/upload/v1668108647/new_header.jpg);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 0px;
}

  .header-contact-button {
    margin: 0;
    border: 1px solid rgba(21, 106, 115, 0.9);
    transition: border-color 150ms ease, background-color 150ms ease;
    border-radius: 3px;
    color: rgba(21, 106, 115, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    height: 28px;
    cursor: pointer;
    font-size: 16px;
  }

  .header-contact-button:hover {
    border-color: rgba(21, 106, 115, 1);
    background-color: rgba(21, 106, 115, 0.2);
  }

  .nav-text-container {
    background: #000405;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: "Work Sans", sans-serif;
    font-weight: 900;
  }

.navbar-container-gradient {
  position: absolute;
  bottom: -16px;
  width: 100%;
  min-height: 16px;
}

.header-title {
  font-size: 20px;
  color: rgba(21, 106, 115, 1);
}

@media screen and (max-width: 600px) {
  .header-title {
    font-size: 14px;
  }
  .header-title1 {
    font-size: 32px;
  }
  .header-subtitle {
    font-size: 14px;
  }

  .header-description {
    width: 100%;
    align-items: center;
  }

  .header {
    width: 100%;
    justify-content: center;
    z-index: 2;
  }
}
